<template>
    <v-form class="sidebar-container" @submit.prevent="searchWithFilters()">

        <!-- Search by EAN -->
        <div class="sidebar-container__section">
            <h3>EAN</h3>
            <v-text-field
                v-model="isbn"
                dense
                hideDetails
                placeholder="EAN"
                backgroundColor="white"
                @keydown.enter="searchWithFilters()"
            />
        </div>

        <!-- Catalog Selection -->
        <div class="sidebar-container__section">
            <div class="sidebar-container__header">
                <h3 class="sidebar-container__header--catalog">Catalog</h3>
            </div>
            <BreadcrumbBase
                v-if="hasLocationList"
                v-model="catalog"
                class="catalog-selection"
                :preSelectedCatalogStack="preSelectedCatalogStack"
                :items="breadcrumbItems"
                @selected="( item ) => catalog = item.breadcrumb"
            />
        </div>

        <!-- Or Filters -->
        <div class="sidebar-container__section">
            <div class="sidebar-container__header">
                <h3>Or</h3>
            </div>
            <MultiOptionBase
                class="filter-options"
                :preSelectedIncludeExcludeOptions="{
                    include: orFilterData.included,
                    exclude: orFilterData.excluded,
                }"
                :fullItemList="orFilterData.options"
                optionKey="id"
                displayedKey="name"
                mode="includeExclude"
                @includeExclude="( inclusiveList, exclusiveList ) => orFilterHandler( inclusiveList, exclusiveList )"
            />
        </div>

        <!-- And Filters -->
        <div class="sidebar-container__section">
            <div class="sidebar-container__header">
                <h3>And</h3>
            </div>
            <MultiOptionBase
                class="filter-options"
                :preSelectedIncludeExcludeOptions="{
                    include: andFilterData.included,
                    exclude: andFilterData.excluded,
                }"
                :fullItemList="andFilterData.options"
                optionKey="id"
                displayedKey="name"
                mode="includeExclude"
                @includeExclude="( inclusiveList, exclusiveList ) => andFilterHandler( inclusiveList, exclusiveList )"
            />
        </div>

        <!-- Search Button -->
        <div class="sidebar-container__section">
            <v-btn
                color="white"
                class="button-apply-filters"
                title="Search with the applied filters"
                type="submit"
            >
                Apply
            </v-btn>
        </div>

    </v-form>
</template>

<script>
// Vuex
import { mapGetters, mapState } from 'vuex';

// Components
import MultiOptionBase from '@/components/base/multioption/MultiOptionBase';
import BreadcrumbBase from '@/components/base/breadcrumbs/BreadcrumbBase';

// Utils
import FormatUtil from '@/utils/FormatUtil';

export default {
    name: 'SidebarFilterAdoptions',
    components: {
        MultiOptionBase,
        BreadcrumbBase,
    },
    computed: {
        ...mapGetters( {
            hasMessagingStatuses: 'Adoption/hasMessagingStatuses',
            messagingStatusList: 'Adoption/messagingStatusList',
            hasSelectedMessagingStatusId: 'Adoption/hasSelectedMessagingStatusId',
            hasSelectedLocation: 'Adoption/hasSelectedLocation',
            hasSelectedDivision: 'Adoption/hasSelectedDivision',
            hasSelectedDepartmentCode: 'Adoption/hasSelectedDepartmentCode',
            hasSelectedCourseCode: 'Adoption/hasSelectedCourseCode',
            hasLocationList: 'GlobalResources/hasLocationList',
        } ),
        ...mapState( {
            catalogFilter: ( state ) => state.Adoption.filters.catalog,
            andFilterData: ( state ) => state.Adoption.filters.includeExclude.and,
            orFilterData: ( state ) => state.Adoption.filters.includeExclude.or,
            isbnFilter: ( state ) => state.Adoption.filters.isbn,
            locationList: ( state ) => state.GlobalResources.locationList,
            // onlyProposedTitles: ( state ) => state.Adoption.filters.onlyProposedTitles,
        } ),
        catalog: {
            get() {
                return this.catalogFilter;
            },
            set( catalog ) {
                this.$store.dispatch( 'Adoption/setCatalog', { catalog } );
            },
        },
        isbn: {
            get() {
                return this.isbnFilter;
            },
            set( value ) {
                this.$store.commit( 'Adoption/SET_FILTERS_ISBN', { isbn: value } );
            },
        },

        /** @returns { Array } */
        preSelectedCatalogStack() {
            const stack = [];
            if ( this.hasSelectedLocation ) stack.push( this.catalogFilter.location );
            if ( this.hasSelectedDivision ) stack.push( this.catalogFilter.division );
            if ( this.hasSelectedDepartmentCode ) stack.push( this.catalogFilter.departmentCode );
            if ( this.hasSelectedCourseCode ) stack.push( this.catalogFilter.courseCode );
            return stack;
        },
        breadcrumbItems() {
            return [
                {
                    placeholder: 'Location',
                    displayedKey: ( location ) => FormatUtil.locationNameToPrettyName( location.name ),
                    actionName: '',
                    childOf: [],
                    list: this.locationList,
                    id: 'location',
                },
                {
                    placeholder: 'Division',
                    displayedKey: 'name',
                    actionName: 'CatalogListing/getDivisionList',
                    childOf: [ 'location' ],
                    list: [],
                    id: 'division',
                },
                {
                    placeholder: 'Department',
                    displayedKey: '',
                    actionName: 'CatalogListing/getDepartmentList',
                    childOf: [ 'division' ],
                    list: [],
                    id: 'department',
                },
                {
                    placeholder: 'Course',
                    displayedKey: '',
                    actionName: 'CatalogListing/getCourseListByDepartmentAndDivision',
                    childOf: [ 'department', 'division' ],
                    list: [],
                    id: 'course',
                },
            ];
        },
    },
    beforeMount() {
        this.$store.dispatch( 'GlobalResources/init' );
    },
    methods: {
        orFilterHandler( inclusiveList, exclusiveList ) {
            this.$store.commit( 'Adoption/SET_FILTERS_OR_INCLUDE_EXCLUDE', {
                includedList: inclusiveList,
                excludedList: exclusiveList,
            } );
        },
        andFilterHandler( inclusiveList, exclusiveList ) {
            this.$store.commit( 'Adoption/SET_FILTERS_AND_INCLUDE_EXCLUDE', {
                includedList: inclusiveList,
                excludedList: exclusiveList,
            } );
        },
        searchWithFilters() {
            this.$store.dispatch( 'Adoption/getAdoptionsByFilters' );
        },
    },
};
</script>

<style scoped lang="scss">
@import "@/assets/sass/variables.scss";
@import "@/assets/sass/sidebar.scss";

.sidebar-container {
    ::v-deep .v-input .v-input__control {
        border-radius: 4px;
    }

    &__section {
        display: flex;
        flex-flow: column nowrap;
        flex: 1 1 auto;
        margin: 0 auto;

        &:not(:last-child) {
            padding-bottom: 1rem;
            width: 100%;
            border-bottom: 1px solid white;
        }

        ::v-deep {
            .multi-option-base__icon {
                display: none;
            }
            select {
                background-color: white;
            }
            a {
                color: var(--v-primary-base);
            }
        }

        .filter-options {
            ::v-deep .reset-link {
                color: white;
            }
        }
    }

    &__header {
        &--catalog {
            margin-bottom: 1rem;
        }
    }

    .button-apply-filters {
        font-weight: bold;
        ::v-deep.v-btn__content {
            color: var(--v-primary-base);
        }
    }
}
</style>
