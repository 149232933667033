<template>
    <div class="adoption-info">

        <!-- Title Info -->
        <div>
            <h3>Title: {{ detail.description || '' }}</h3>
        </div>

        <!-- Catalog Listings -->
        <div>
            <h3>Catalog Listings ( {{ catalogListingCount }} )</h3>
            <TableBase
                :data="catalogListingList"
                :columns="columnList"
                :loading="inFlightDetail || inFlightListings"
                hoverable
                defaultSort="section.startDate"
            />
        </div>

        <!-- Button Group -->
        <div class="adoption-info__button-group">
            <v-btn
                color="info"
                :disabled="!hasMaterialLink"
                @click="goToButlerCentral()"
            >
                <v-icon left>mdi-email-outline</v-icon>
                View Message
            </v-btn>
            <v-btn
                color="primary"
                :disabled="!hasCatalogListings"
                @click="goToCatalogListings()"
            >
                <v-icon left>mdi-magnify</v-icon>
                Inspect Catalog Listings
            </v-btn>
        </div>

    </div>
</template>

<script>
// Vuex
import { mapGetters } from 'vuex';

// Api
import AdoptionApi from '@/api/AdoptionApi';

// Services
import PageService from '@/services/PageService';

// Utils
import ObjectUtil from '@/utils/ObjectUtil';

// Components
import TableBase from '@/components/base/table/TableBase';

export default {
    name: 'AdoptionInfo',
    components: {
        TableBase,
    },
    props: {
        /** @type { import('@/typedefs/adoption').adoption } */
        adoptionInfo: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            detail: {},
            inFlightDetail: false,
            adoptedTitles: {},
            inFlightListings: false,

            columnList: [
                {
                    label: 'Division',
                    field: 'catalogListing.divisionName',
                    sortable: true,
                },
                {
                    label: 'Term',
                    field: 'catalogListing.termName',
                    sortable: true,
                },
                {
                    label: 'Course',
                    field: 'catalogListing.course.code',
                    sortable: true,
                },
                {
                    label: 'Section',
                    field: 'catalogListing.section.code',
                    sortable: true,
                },
                {
                    label: 'Section Shelf Date',
                    field: 'shelfDate',
                    sortable: true,
                },
                {
                    label: 'Section Start Date',
                    field: 'catalogListing.section.startDate',
                    sortable: true,
                },
                {
                    label: 'Actual Enrollment',
                    field: 'catalogListing.section.enrollment',
                    sortable: true,
                },
                {
                    label: 'Estimated Enrollment',
                    field: 'catalogListing.section.estimatedEnrollment',
                    sortable: true,
                },
                {
                    label: 'QTP',
                    field: 'catalogListing.qtp',
                    sortable: true,
                },
                {
                    label: 'Listing Status',
                    field: 'catalogListing.status',
                    sortable: true,
                },
            ],
        };
    },
    computed: {
        ...mapGetters( {
            authToken: 'User/authString',
        } ),
        /** @returns { Number || String } */
        adoptionId() {
            return this.adoptionInfo.adoptionMaterialId;
        },
        /** @returns { Array } */
        catalogListingList() {
            return Object.values( this.adoptedTitles );
        },
        /** @returns { Number } */
        catalogListingCount() {
            return this.catalogListingList.length;
        },
        /** @returns { Boolean } */
        hasCatalogListings() {
            return this.catalogListingCount > 0;
        },
        /** @returns { Boolean } */
        hasDetail() {
            return ObjectUtil.isNotEmpty( this.detail );
        },
        /** @returns { Boolean } */
        hasMaterialLink() {
            return this.hasDetail
                && this.detail.materialLink;
        },
    },
    mounted() {
        this.getAdoptionDetailInfo();
        this.getCatalogListingsByAdoptionId();
    },
    methods: {
        async getAdoptionDetailInfo() {
            this.inFlightDetail = true;
            try {
                const { data } = await AdoptionApi.getAdoptionsByFilters( {
                    authToken: this.authToken,
                    payload: { adoptionMaterialId: this.adoptionId },
                } );
                if ( data.adoptionMaterialItems && data.adoptionMaterialItems.length && data.adoptionMaterialItems.length > 0 ) {
                    [ this.detail ] = data.adoptionMaterialItems;
                }
            } catch ( error ) {
                console.error( 'Error in getAdoptionDetailInfo: ', error );
            } finally {
                this.inFlightDetail = false;
            }
        },
        async getCatalogListingsByAdoptionId() {
            this.inFlightListings = true;
            try {
                const { data } = await AdoptionApi.getCatalogListingsByAdoption( {
                    authToken: this.authToken,
                    payload: {
                        adoptionMaterialId: this.adoptionId,
                    },
                } );
                if ( data.titleResearchListDTOList && data.titleResearchListDTOList.length && data.titleResearchListDTOList.length > 0 ) {
                    const mutatedResponse = data.titleResearchListDTOList.map( ( item ) => ( {
                        ...item,
                        uid: item.catalogListing.id,
                    } ) );
                    this.adoptedTitles = ObjectUtil.normalize( mutatedResponse, 'uid' );
                }
            } catch ( error ) {
                console.error( 'Error in getCatalogListingsByAdoptionId: ', error );
            } finally {
                this.inFlightListings = false;
            }
        },

        goToButlerCentral() {
            if ( this.hasMaterialLink ) PageService.openNewTab( `${ this.detail.materialLink }` );
        },
        goToCatalogListings() {
            if ( this.hasCatalogListings ) {
                if ( window && window.location && window.location.origin ) {
                    PageService.openNewTab( `${ window.location.origin }/#/catalog-listings?adoptionMaterialId=${ this.adoptionId }` );
                } else {
                    // eslint-disable-next-line no-alert
                    alert( 'Failed to open a new tab to view Catalog Listings' );
                }
            }
        },
    },
};
</script>

<style scoped lang="scss">
@import "@/assets/sass/variables.scss";

.adoption-info {
    border-radius: 5px;
    background-color: #f0f0f0;
    padding: 1rem;
    box-shadow: $material-shadow-1;

    &:hover {
        box-shadow: $material-shadow-2;
        transition: $material-shadow-hover;
        background-color: #fafafa; // $alabaster
    }

    &__button-group {
        margin-top: 1rem;
        :not(:last-child) {
            margin-right: 1rem;
        }
    }
}
</style>
