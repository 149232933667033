<template>
    <div class="custom-container" :class="[ fullSidebar ? 'sidebar-full' : 'sidebar-thin' ]">

        <TheSidebar
            :fullSize="fullSidebar"
        >
            <!-- Sidebar Slot -->
            <slot name="sidebar"></slot>
        </TheSidebar>

        <ThePageHeadingBar
            :fullSize="fullSidebar"
            :pageHeading="pageHeading"
            :backArrow="canGoBack"
            @goBack="$emit( 'goBack' )"
        />

        <section class="main">
            <!-- Main Content Slot -->
            <slot name="main"></slot>
        </section>

        <ThePageFooterBar>
            <!-- Footer Slot -->
            <slot name="footer"></slot>
        </ThePageFooterBar>

    </div>
</template>

<script>
// Components
import ThePageHeadingBar from '@/components/ThePageHeadingBar';
import ThePageFooterBar from '@/components/ThePageFooterBar';
import TheSidebar from '@/views/common/TheSidebar';

export default {
    name: 'PageLayout',
    components: {
        ThePageHeadingBar,
        ThePageFooterBar,
        TheSidebar,
    },
    props: {
        fullSidebar: {
            type: Boolean,
            required: false,
            default: false,
        },
        pageHeading: {
            type: String,
            default: 'Oops, no pageHeading passed as a prop to PageLayout',
            required: false,
        },
        canGoBack: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
};
</script>

<style scoped>

</style>
