<template>
    <div class="table-adoption-search-results">
        <div class="result-count-container">
            Results: {{ resultListCount }}
        </div>

        <TableBase
            :data="resultList"
            :columns="columnList"
            :loading="inFlight"
            detailed
            hoverable
            paginated
            perPage="20"
            defaultSort="nextSectionShelfDate"
        >
            <template
                slot="detail"
                slot-scope="props"
            >
                <AdoptionInfo
                    :adoptionInfo="props.row"
                />
            </template>
        </TableBase>
    </div>
</template>

<script>
// Vuex
import { mapGetters, mapState } from 'vuex';

// Components
import TableBase from '@/components/base/table/TableBase';

import AdoptionInfo from './tableAdoptionSearchResults/AdoptionInfo';

export default {
    name: 'TableAdoptionSearchResults',
    components: {
        TableBase,
        AdoptionInfo,
    },
    data() {
        return {
            columnList: [
                {
                    label: 'Division',
                    field: 'divisionName',
                    sortable: true,
                },
                {
                    label: 'Course',
                    field: 'courseCode',
                    sortable: true,
                },
                {
                    label: 'Required / Optional',
                    field: 'requiredFriendly',
                    sortable: true,
                },
                {
                    label: 'Auto Adoption',
                    field: 'autoAdoptionFriendly',
                    sortable: true,
                },
                {
                    label: 'Effective Date',
                    field: 'effectiveDate',
                    sortable: true,
                },
                {
                    label: 'Expiration Date',
                    field: 'expirationDate',
                    sortable: true,
                },
                {
                    label: 'EAN',
                    field: 'productCode',
                    sortable: true,
                },
                {
                    label: 'Next Section Shelf Date',
                    field: 'nextSectionShelfDate',
                    sortable: true,
                },
                {
                    label: 'Next Section Start Date',
                    field: 'nextSectionStartDate',
                    sortable: true,
                },
                {
                    label: 'Message Status',
                    field: 'materialMessagingStatus',
                    sortable: true,
                },
                {
                    label: 'Last Message Status Change Date',
                    field: 'lastMessageStatusChangeDate',
                    sortable: true,
                },
                {
                    label: 'Type',
                    field: 'typeFriendly',
                    sortable: true,
                },
            ],
        };
    },
    computed: {
        ...mapGetters( {
            resultList: 'Adoption/resultList',
            resultListCount: 'Adoption/resultListCount',
        } ),
        ...mapState( {
            inFlight: ( state ) => state.Adoption.results.inFlight,
        } ),
    },
    methods: {
        rowClickHandler( { adoptionMaterialId } ) {
            this.$router.push( {
                name: 'AdoptionDetail',
                params: { id: adoptionMaterialId },
            } );
        },
    },
};
</script>

<style lang="scss" scoped>
    .table-adoption-search-results {
        margin-bottom: 5rem;
        .result-count-container {
            color: #808080;
            margin-bottom: 0.5rem;
            padding-bottom: 0.5rem;
            border-bottom: 1px solid #d3d3d3;
        }
    }
</style>
