<template>
    <TheBasePageLayout
        fullSidebar
        pageHeading="Adoptions"
    >

        <!-- Sidebar -->
        <div slot="sidebar">
            <SidebarFilterAdoptions/>
        </div>

        <!-- Main -->
        <div slot="main">
            <TableAdoptionSearchResults/>
        </div>

        <!-- Footer -->
        <!--<div slot="footer"></div>-->

    </TheBasePageLayout>
</template>

<script>
// Components
import TheBasePageLayout from '@/components/base/TheBasePageLayout';

import SidebarFilterAdoptions from './adoptionSearch/SidebarFilterAdoptions';
import TableAdoptionSearchResults from './adoptionSearch/TableAdoptionSearchResults';

export default {
    name: 'AdoptionSearch',
    components: {
        TheBasePageLayout,
        SidebarFilterAdoptions,
        TableAdoptionSearchResults,
    },
};
</script>
